import router from '@/router/index';

export default class Redirect {
    static async to(to) {
        let finalTo = to;

        let method = 'push';

        let currentRoute = router.currentRoute;

        if (typeof finalTo === 'string') {
            finalTo = {
                name: finalTo,
            };
        }

        if (finalTo.name === undefined) {
            throw new Error('No name provided, not redirecting!');
        }

        if (finalTo.preventNamePrefix !== true) {
            if (
                currentRoute.meta
                && currentRoute.meta.appliedNamePrefix
            ) {
                let namePrefix = currentRoute.meta.appliedNamePrefix;

                finalTo.name = namePrefix + '_' + finalTo.name;
            }
        }

        if (finalTo.replace) {
            method = 'replace';
        }

        if (!finalTo.withoutQuery) {
            if (typeof finalTo.query === 'object') {
                if (typeof currentRoute.query === 'object') {
                    finalTo.query = Object.assign(finalTo.query, currentRoute.query);
                }
            } else {
                finalTo.query = currentRoute.query;
            }
        }

        try {
            await router[method](finalTo);
        } catch(e) {
            if (e.name !== 'NavigationDuplicated') {
                console.error(e);
            }
        }
    }

    static back() {
        router.go(-1);
    }

    static path(path) {
        router.push({path});
    }
}
