<template>
    <auth-default>
        <b-card class="text-center">
            <b-card-title
                class="mb-4 font-weight-bold"
                title-tag="h4"
            >
                {{ $t('invitations.accept.title') }}
            </b-card-title>

            <wait-for-resource
                error-text=""
                :resource="resource"
            />

            <div
                v-if="success"
                class="text-success"
            >
                <fa
                    class="mb-2"
                    icon="check"
                    size="lg"
                />

                <div>
                    {{ $t('invitations.messages.acceptSuccess') }}
                </div>
            </div>
        </b-card>
    </auth-default>
</template>

<script>
import Model from '@/models/vue-mc/Model';
import Redirect from '@/library/Redirect';
import {detainFeedback} from '@/library/helpers';
import AuthDefault from '@/components/auth/Default';

export default {
    name: 'InvitationsAccept',
    components: {AuthDefault},
    data() {
        return {
            resource: new Model(
                {token: null},
                null,
                {endpoint: `invitations/${this.$route.query.invitation_id}/accept`},
            ),
            success: false,
        };
    },
    async created() {
        this.resource.token = this.$route.query.invitation_token;

        try {
            await this.resource.save();

            this.success = true;

            detainFeedback(() => {
                Redirect.to({
                    name: 'home',
                    withoutQuery: true,
                });
            }, 5000);
        } catch (e) {
            this.$set(this.resource, 'fatal', true);

            throw e;
        }
    },
};
</script>
